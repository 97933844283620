<template>
    <BookWithUs :blok="content" />
</template>

<script setup lang="ts">
import BookWithUs from '~/components/sections/BookWithUs.vue';
import type { IBookWithUs, IBookWithUsContainer } from '~/types/BookWithUs';

const { blok } = defineProps<{ blok: IBookWithUsContainer }>();
const uuid = blok.Content;

const content = await $resolvedLinkComponent<IBookWithUs>(uuid);
</script>

<style lang="postcss" scoped></style>
